import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { getOtherOptionsFromWindow, createRegxpPatternFromWildcardedName } from "../utils";
import { getUserDecision } from "../others";
/**
 * Synchronously check if a consent is given. See `window.consentApi.consent` for more documentation.
 */

function consentSync(typeOrId, name,
/**
 * This can also be `*` to allow all hosts.
 */
host) {
  // Find matching cookie
  var found = [];

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      groups = _getOtherOptionsFromW.groups,
      revisionHash = _getOtherOptionsFromW.revisionHash;

  var allCookies = groups.map(function (_ref) {
    var items = _ref.items;
    return items;
  }).flat();

  var _iterator = _createForOfIteratorHelper(allCookies),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var row = _step.value;

      if (typeof typeOrId === "number") {
        if (row.id === typeOrId) {
          found.push({
            cookie: row,
            relevance: 10
          });
        }
      } else {
        var technicalDefinitions = row.technicalDefinitions;

        if (technicalDefinitions !== null && technicalDefinitions !== void 0 && technicalDefinitions.length) {
          // Check if technical information matches
          var _iterator2 = _createForOfIteratorHelper(technicalDefinitions),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var td = _step2.value;
              var regexp = createRegxpPatternFromWildcardedName(td.name);

              if (td.type === typeOrId && (td.name === name || name.match(regexp)) && (td.host === host || host === "*")) {
                found.push({
                  cookie: row,
                  // Create a priority by "relevance" inside the technical definitions
                  // This is the case if e.g. another Cookie consumes the same technical cookie
                  // Example: Vimeo uses Facebook Pixel, too
                  relevance: technicalDefinitions.length + technicalDefinitions.indexOf(td) + 1
                });
                break;
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  if (found.length) {
    var relevantCookie = found.sort(function (_ref2, _ref3) {
      var a = _ref2.relevance;
      var b = _ref3.relevance;
      return a - b;
    })[0].cookie; // Check if consent is already given

    var already = getUserDecision();

    if (already && revisionHash === already.revision) {
      var consentCookieIds = Object.values(already.consent).flat();

      if (consentCookieIds.indexOf(relevantCookie.id) > -1) {
        return {
          cookie: relevantCookie,
          consentGiven: true,
          cookieOptIn: true
        };
      } else {
        return {
          cookie: relevantCookie,
          consentGiven: true,
          cookieOptIn: false
        };
      }
    } else {
      return {
        cookie: relevantCookie,
        consentGiven: false,
        cookieOptIn: false
      };
    }
  } else {
    return {
      cookie: null,
      consentGiven: !!getUserDecision(),
      cookieOptIn: true
    };
  }
}

export { consentSync };