import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { getOtherOptionsFromWindow } from "../utils";
import { useCallback } from "react";

function useWebsiteBannerOverlay() {
  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      pageRequestUuid4 = _getOtherOptionsFromW.pageRequestUuid4;

  var overlay = document.getElementById(pageRequestUuid4);
  var toggleOverlay = useCallback(function (_ref, show) {
    var _ref$layout = _ref.layout,
        animationIn = _ref$layout.animationIn,
        animationInDuration = _ref$layout.animationInDuration,
        animationOut = _ref$layout.animationOut,
        animationOutDuration = _ref$layout.animationOutDuration;
    var defaultIfNoAnimation = 0; // Avoid "hard" overlay

    var useDuration = show ? animationIn === "none" ? defaultIfNoAnimation : animationInDuration : animationOut === "none" ? defaultIfNoAnimation : animationOutDuration; // Toggle SSR-rendered overlay

    useDuration > 0 && (overlay.style.transition = "background ".concat(useDuration, "ms"));
    overlay.style.background = "transparent";

    if (show) {
      // We need a small delay to avoid flickering of animation out effect in customizer
      setTimeout(function () {
        overlay.style.display = "block";
      }, 0);
      setTimeout(function () {
        var bg = overlay.getAttribute("data-bg");
        overlay.style.background = bg ? bg.split(":")[1].trim().replace(";", "") : "transparent";
      }, 100);
    } else {
      setTimeout(function () {
        overlay.style.display = "none";
      }, useDuration);
    }

    var animationVisible = {
      animationVisible: show
    };
    return show ? _objectSpread({
      visible: true
    }, animationVisible) : animationVisible;
  }, []);
  return {
    overlay: overlay,
    toggleOverlay: toggleOverlay
  };
}

export { useWebsiteBannerOverlay };